import React, { useState } from "react";
// lib
import i18n from "i18next";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// shared-comp
import HeaderMarquee from "../../components/shared_comp/HeaderMarquee";
// images
import HeaderIcon from "../../asset/image/auland.png";
import MMFlag from "../../asset/image/localization/mm.png";
import UKFlag from "../../asset/image/localization/uk.png";
import ChinaFlag from "../../asset/image/localization/china.png";
import ThaiFlag from "../../asset/image/localization/tai.png";
// utils
import { moneyFormatter } from "../../utils/moneyFormatter";
import { removeLocalStorageNoJson } from "../../utils/localStorage";
// config
import { CN, CURRENT_LANG, EN, MM, THAI } from "../../localization/langConfig";

const FlagImage = ({ flagImg, width }) => {
  return (
    <img style={{ width, objectFit: "contain" }} src={flagImg} alt="..." />
  );
};

const WebsiteHeader = ({
  userData,
  handleToggle,
  handleLogout,
  isDisabled,
  marquee,
}) => {
  let { t } = useTranslation();
  const getLang = removeLocalStorageNoJson(CURRENT_LANG);
  const [language, setchangeLanguage] = useState(getLang ? getLang : MM);
  const [openLang, setOpenLang] = useState(false);
  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    setchangeLanguage(lang);
    setOpenLang(false);
  };

  return (
    <div
      className="d-none position-relative d-lg-flex justify-content-end justify-content-md-between align-items-center"
      style={{ height: "40px" }}
    >
      {/* Lang Switch */}
      <div
        className="d-flex justify-content-end pe-3"
        style={{ paddingLeft: "100px" }}
      >
        <div
          onClick={() => setOpenLang(!openLang)}
          className="d-flex cursor-pointer align-items-center px-2"
        >
          {language === EN ? (
            <FlagImage width={35} flagImg={UKFlag} />
          ) : language === MM ? (
            <FlagImage width={35} flagImg={MMFlag} />
          ) : language === CN ? (
            <FlagImage width={35} flagImg={ChinaFlag} />
          ) : (
            <FlagImage width={35} flagImg={ThaiFlag} />
          )}
          {/* Expand Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#ffff"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
          </svg>
        </div>
        {/* Announcement Icon */}
        <img width={30} height={30} src={HeaderIcon} alt="..." />
      </div>
      {/* Land Switch: DropDown  */}
      <div
        className={`position-absolute ${
          openLang ? "d-flex" : "d-none"
        } flex-column gap-1 bg-white rounded-3 p-2`}
        style={{ left: 107, top: 40, zIndex: 9999 }}
      >
        <div
          className={`${
            language !== MM ? "d-flex" : "d-none"
          } gap-2 align-items-center cursor-pointer`}
          onClick={() => changeLang(MM)}
        >
          <FlagImage width={25} flagImg={MMFlag} />
          <span>ဗမာစာ</span>
        </div>
        <div
          className={`${
            language !== EN ? "d-flex" : "d-none"
          } gap-2 align-items-center cursor-pointer`}
          onClick={() => changeLang(EN)}
        >
          <FlagImage width={25} flagImg={UKFlag} />
          <span>English</span>
        </div>
        <div
          className={`${
            language !== THAI ? "d-flex" : "d-none"
          } gap-2 align-items-center cursor-pointer`}
          onClick={() => changeLang(THAI)}
        >
          <FlagImage width={25} flagImg={ThaiFlag} />
          <span>ไทย</span>
        </div>
        <div
          className={`${
            language !== CN ? "d-flex" : "d-none"
          } gap-2 align-items-center cursor-pointer`}
          onClick={() => changeLang(CN)}
        >
          <FlagImage width={25} flagImg={ChinaFlag} />
          <span>中国人</span>
        </div>
      </div>
      <div className="bs-flex d-flex align-items-center">
        <HeaderMarquee marquee={marquee} />
      </div>
      <div className="d-flex justify-content-end align-items-center">
        {userData?.token ? (
          <div
            className="d-flex ps-3 justify-content-between align-items-center gap-3"
            style={{ color: "goldenrod" }}
          >
            <div className="bg-white rounded-pill p-1 text-center d-flex flex-wrap justify-content-center align-items-center">
              {/* Main Balance */}
              <svg
                className="float-start"
                fill="goldenrod"
                xmlns="http://www.w3.org/2000/svg"
                height={21}
                width={21}
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z" />
              </svg>
              <span className="px-1 px-md-3 text-black fw-bold">
                {userData?.balance
                  ? moneyFormatter(parseInt(userData?.balance || 0))
                  : 0}
              </span>
            </div>

            <div className="dropdown" style={{ cursor: "pointer" }}>
              <span
                className="p-1  "
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="rounded-circle bg-white p-2">
                  <i className="fa-solid fa-user-tie text-dark "></i>
                </span>
              </span>

              <ul
                style={{ zIndex: "10000" }}
                className="dropdown-menu border border-info border-2 bg-white  rounded-3"
              >
                <Link to="/profile" className="text-decoration-none ">
                  <li className="profileBtn ps-3 py-1 text-dark">
                    {t("profile")}
                  </li>
                </Link>

                <Link onClick={handleLogout} className="text-decoration-none">
                  <li className="profileBtn ps-3 py-1 text-dark">
                    {t("logout")}
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        ) : (
          <div className="w-100" style={{ color: "goldenrod" }}>
            <span className="fw-bolder ">welcome</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default WebsiteHeader;
