import React from "react";

const HeaderMarquee = ({ marquee }) => {
  return (
    <marquee style={{ color: "white", fontSize: "15px", fontWeight: "800" }}>
      {marquee}
    </marquee>
  );
};
export default HeaderMarquee;
