import React from "react";
// lib
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// lib - css
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// images
import Logo_1 from "../asset/image/providers/logo_1.png";
import Logo_2 from "../asset/image/providers/logo_2.png";
import Logo_3 from "../asset/image/providers/logo_3.png";
import Logo_4 from "../asset/image/providers/logo_4.png";
import Logo_5 from "../asset/image/providers/logo_5.png";
import Logo_6 from "../asset/image/providers/logo_6.png";
import Logo_7 from "../asset/image/providers/logo_7.png";
import Logo_8 from "../asset/image/providers/logo_8.png";
import Logo_9 from "../asset/image/providers/logo_9.png";
import Logo_10 from "../asset/image/providers/logo_10.png";
import Logo_11 from "../asset/image/providers/logo_11.png";
import Logo_12 from "../asset/image/providers/logo_12.png";
import Logo_13 from "../asset/image/providers/logo_13.png";
import Logo_14 from "../asset/image/providers/logo_14.png";

function Footer({ socialLink }) {
  let providerLinks = [
    Logo_1,
    Logo_2,
    Logo_3,
    Logo_4,
    Logo_5,
    Logo_6,
    Logo_7,
    Logo_8,
    Logo_9,
    Logo_10,
    Logo_11,
    Logo_12,
    Logo_13,
    Logo_14,
  ];

  return (
    <div className="mt-auto theme-color">
      {/* Partner */}
      {/* Footer */}
      <div className="theme-color px-4 py-1 d-flex justify-content-between align-items-center">
        <div className="">
          {/* FB  */}
          <a
            href={socialLink?.facebook}
            className="text-decoration-none pe-3 text-black"
          >
            <i className="fa-brands fs-3 fa-facebook"></i>
          </a>
          {/* Messenger */}
          <a
            href={socialLink?.messenger}
            className="text-decoration-none pe-3 text-black"
          >
            <i className="fa-brands fs-3 fa-facebook-messenger"></i>
          </a>
          {/* Viber */}
          <a
            href={socialLink?.viber}
            className="text-decoration-none pe-3 text-black"
          >
            <i className="fa-brands fs-3 fa-viber"></i>
          </a>
          {/* Instagram */}
          <a
            href={socialLink?.instagram}
            className="text-decoration-none pe-3 text-black"
          >
            <i className="fa-brands fs-3 fa-instagram"></i>
          </a>
        </div>
        <div>
          <small className="fw-bold text-muted">© Copyright MYVIP</small>
        </div>
      </div>
    </div>
  );
}

export default Footer;
