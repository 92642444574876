import React from "react";
// lib
import { useNavigate } from "react-router-dom";

const PopupBox = ({
  handleToggle,
  id,
  children,
  title = "Popup Box",
  contentStyle = {},
  showBack = false,
  isTitleCenter = false,
}) => {
  let navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="popup bg-danger col-12 col-sm-12 col-md-5 bg-white" id={id}>
      <div className="overlay"></div>
      <div className="content col-12 col-sm-12 col-md-5 bg-white bg-opacity-75 rounded-4" style={contentStyle}>
        {showBack && (
          <div
            onClick={goBack}
            className="back-btn cursor-pointer d-flex align-items-center justify-content-center"
            style={{ cursor: "pointer" }}
          >
            {/* Back Svg */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="21"
              viewBox="0 0 24 24"
              width="21"
              fill="#ffffff"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
            </svg>
          </div>
        )}
        <div
          className="close-btn cursor-pointer"
          id={id}
          onClick={handleToggle}
        >
          &times;
        </div>
        <h4 className={`${isTitleCenter && "text-center"} fw-bold`}>{title}</h4>
        {children}
      </div>
    </div>
  );
};

export default PopupBox;
