// Api URL
import { getConfig } from "./apiHeader"
// Api Header
import { GetBannerURL, GetHeaderTextURL, GetHotGamesByCatURL, GetMobileBannerURL, GetSocialLinkURL } from "./apiUrl"

export const getBannerApi = async () => {
  const res = await fetch(GetBannerURL, getConfig)
  if (res.ok) return await res.json()
  else throw res
}

export const getMobileBannerApi = async () => {
  const res = await fetch(GetMobileBannerURL, getConfig)
  if (res.ok) return await res.json()
  else throw res
}


export const getSocialLinkApi = async () => {
  const res = await fetch(GetSocialLinkURL, getConfig);
  if (res.ok) return await res.json();
  else throw res;
}

export async function getHeaderTextApi() {
  const res = await fetch(GetHeaderTextURL, getConfig);
  if (res.ok) {
    let resData = await res.json();
    let respond = resData?.data[0]?.text;
    return respond;
  }
  else throw res;
}
