import React from "react";
// lib
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// image
import logo from "../asset/image/9d_icon.jpg";

function Navbar() {
  let { t } = useTranslation();
  let location = useLocation();
  return (
    <div className="d-none d-md-flex col-8 p-3 row gap-5 align-items-center justify-content-center offset-2 bg-opacity-75 rounded-top rounded-5 NavBar">
      <NavLink to="/" className="col-2">
        <div>
          <img src={logo} width={60} alt="" />
        </div>
      </NavLink>

      <NavLink
        to="/"
        className={`text-decoration-none col-1 rounded-pill text-center p-2 ${
          location.pathname === "/" ? "nav_btn" : "bg-white"
        } border border-2 border-dark`}
      >
        <span className="text-center text-black">{t("home")}</span>
      </NavLink>

      <NavLink
        to="/win-number"
        className={`text-decoration-none col-2 rounded-5 text-center p-2 ${
          location.pathname === "/win-number" ? "nav_btn" : "bg-white"
        } border border-2 border-dark`}
      >
        <span className="text-center text-black">{t("winNoHistory")}</span>
      </NavLink>

      <NavLink
        to="/winner-list"
        className={`text-decoration-none col-2 rounded-5 text-center p-2 ${
          location.pathname === "/winner-list" ? "nav_btn" : "bg-white"
        } border border-2 border-dark`}
      >
        <span className="text-center text-black">
          {t("winnerListHistory.title")}
        </span>
      </NavLink>

      <NavLink
        to="/betslip"
        className={`text-decoration-none col-2 rounded-5 text-center p-2 ${
          location.pathname === "/betslip" ? "nav_btn" : "bg-white"
        } border border-2 border-dark`}
      >
        <span className="text-center text-black">{t("history")}</span>
      </NavLink>
    </div>
  );
}

export default Navbar;
