// Api Header
import { getConfig, postConfig } from "./apiHeader";
// Api URL
import {
  ChangePassURL,
  GetUserProfileURL,
  UpdateUserProfileURL,
  UpgradeLevel2URL,
} from "./apiUrl";

export async function getProfileApi({ token }) {
  const res = await fetch(GetUserProfileURL, {
    ...getConfig,
    headers: {
      ...getConfig?.headers,
      Authorization: `Bearer ${token}`,
    },
  });
  if (res.ok) return await res.json();
  else throw res;
}

export async function updateProfileApi({ body, token }) {
  let config = {
    ...postConfig(token),
    body: JSON.stringify(body),
  };
  if (body?.image) {
    config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: body?.image,
    };
  }
  const res = await fetch(UpdateUserProfileURL, config);
  if (res.ok) return res.json();
  else throw await res.json();
}

export async function changePassApi({ body, token }) {
  const res = await fetch(ChangePassURL, {
    ...postConfig(token),
    body: JSON.stringify(body),
  });
  if (res.ok) return res.json();
  else throw await res.json();
}

export async function upgradeLevel2Api({ body, token }) {
  const res = await fetch(UpgradeLevel2URL, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body,
  });
  if (res.ok) return res.json();
  else throw await res.json();
}
