// The whole project URL files.
// export const BaseURL = "http://9dragonlottery.test";
export const BaseURL = "https://backend.9dragon.org";

// For Auth Api
export const LoginURL = `${BaseURL}/api/user/login`;
export const CheckPhoneURL = `${BaseURL}/api/user/check_phone`;
export const GetOtpURL = `${BaseURL}/api/user/send_otp`;
export const VerifyOtpURL = `${BaseURL}/api/user/verify_otp`;
export const RegisterURL = `${BaseURL}/api/user/verify_register`;
export const ResetPasswordURL = `${BaseURL}/api/user/forget_password`;
export const SaveDeviceTokenURL = `${BaseURL}/api/user/save_device_token`;

// For Home Api
export const GetBannerURL = `${BaseURL}/api/banner`;
export const GetMobileBannerURL = `${BaseURL}/api/banner_mobile`;
export const GetHotGamesByCatURL = `${BaseURL}/api/games/hot?category_id=`;
export const GetSocialLinkURL = `${BaseURL}/api/social_link`;
export const GetHeaderTextURL = `${BaseURL}/api/header_play_text`;

// For All Games : football, live-casino, slot, card-games, fishing, other games
export const GameTransferin = `${BaseURL}/api/user/transfer_in`;
export const GameTransferout = `${BaseURL}/api/user/transfer_out`;

// For Payment Api
export const GetCashInPaymentsURL = `${BaseURL}/api/payment-method/show-cashin`;
export const GetCashInAmountURL = `${BaseURL}/api/cashin-amount`;
export const GetCashOutPaymentsURL = `${BaseURL}/api/payment-method/show-cashout`;
export const GetCashOutAmountURL = `${BaseURL}/api/cashout-amount`;
export const ReqCashInPaymentURL = `${BaseURL}/api/cash-in`;
export const ReqCashOutPaymentURL = `${BaseURL}/api/cash-out`;

// For Profile Api
export const GetUserProfileURL = `${BaseURL}/api/user/profile`;
export const UpdateUserProfileURL = `${BaseURL}/api/user/profile_update`;
export const ChangePassURL = `${BaseURL}/api/user/new_password`;
export const UpgradeLevel2URL = `${BaseURL}/api/user/lvl_2`;

// For Two D Lottery Api
export const GetTwoDLiveDataURL = "https://api.thaistock2d.com/live27";
export const GetLuckyNumbersURL = `${BaseURL}/api/luckynumber_daily`;
export const GetSectionsURL = `${BaseURL}/api/section`;
export const GetTwoDDataURL = `${BaseURL}/api/new_two_d`;
export const Thai2DBetURL = `${BaseURL}/api/user/betting`;

// For Three D Lottery Api
export const GetThreeDDataURL = `${BaseURL}/api/three_d`;
export const GetThreeDSectionsURL = `${BaseURL}/api/section_3d`;
export const Thai3DBetURL = `${BaseURL}/api/user/betting_3d`;

// For History Api
export const GetTwoDHistoryURL = `${BaseURL}/api/user/bet_slip_history_2d`;
export const GetThreeDHistoryURL = `${BaseURL}/api/user/bet_slip_history_3d`;
export const GetCashInHistoryURL = `${BaseURL}/api/user/cash_in_history`;
export const GetCashOutHistoryURL = `${BaseURL}/api/user/cash_out_history`;
export const GetWinNumberHistoryURL = `${BaseURL}/api/luckynumber_history`;
export const GetWinnerListHistoryURL = `${BaseURL}/api/winner_users`;
export const SecretKeyBylogin = `${BaseURL}/api/user/secretkeybylogin`;
